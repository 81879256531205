import React, { Children, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import Arrow from 'assets/svg/icons/arrow.svg';

import { UIContext } from 'context/ui';

import { Container } from 'components/layout/Container';

import s from './Pagination.scss';

interface IProps {
  children: React.ReactNode;
  showPrevious?: boolean;
  showNext?: boolean;
  page: number;
  onClick: (action: any) => void;
}

export const Pagination = ({ children, showPrevious, showNext, page, onClick }: IProps) => {
  const { isMobile } = useContext<any>(UIContext);

  function onPrevClickHandler() {
    onClick(page - 1);
  }

  function onNextClickHandler() {
    onClick(page + 1);
  }

  return (
    <div className={s('pagination')}>
      <Container>
        <div className={s.pagination__inner}>
          <ul className={s.pagination__list} role="navigation" aria-label="Search results pagination">
            {showPrevious && (
              <li className={s.pagination__previous}>
                <button onClick={onPrevClickHandler} className={s('pagination__navButton', 'previous')}>
                  <Arrow className={s.pagination__arrow} /><FormattedMessage id="previous" defaultMessage="Previous" /></button>
              </li>
            )}
            {!showPrevious && (
              <div className={s.pagination__previous} />
            )}
            {!isMobile && Children.map(children, (child, i) => (
              <li className={s.pagination__item} key={`${s.pagination}-${i}`}>{child}</li>
            ))}
            {showNext && (
              <li className={s.pagination__next}>
                <button onClick={onNextClickHandler} className={s('pagination__navButton', 'next')}>
                  <FormattedMessage id="next" defaultMessage="Next" />
                  <Arrow className={s.pagination__arrow} />
                </button>
              </li>
            )}
            {!showNext && (
              <div className={s.pagination__next} />
            )}
          </ul>
        </div>
      </Container>
    </div>
  );
};

Pagination.defaultProps = {
  showPrevious: true,
  showNext: true,
};
