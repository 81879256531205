import React, { useRef, useEffect, useState, cloneElement } from 'react';
import gsap from 'gsap';

import { UIContext } from 'context/ui';

import { Container } from 'components/layout/Container';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './CategoryHero.scss';

interface IProps {
  children: React.ReactElement<any>;
  kicker?: string | React.ReactElement<any>;
  heading: string;
  align?: 'left' | 'right'; // relevant text position rather than video
}

export const CategoryHero = ({ children, heading, kicker, align }: IProps) => {
  const { isMobile, setHeaderTheme } = React.useContext<any>(UIContext);

  const overlayNode = useRef<HTMLDivElement>(null);
  const backgroundNode = useRef<HTMLDivElement>(null);
  const backgroundInnerNode = useRef<HTMLDivElement>(null);
  const wrapperNode = useRef<HTMLDivElement>(null);
  const [complete, setComplete] = useState(false);

  const percentage = (5.5 / 12) * 100;
  const backgroundWidth = align === 'left' ? Math.abs(percentage) : -Math.abs(percentage);
  const backgroundPosition = align === 'left' ? '-30%' : '30%';

  const setHeaderConditional = () => {
    setHeaderTheme(isMobile ? 'light' : align === 'left' ? 'controlsLight' : 'logoLight');
  };

  const renderKicker = () => {
    if (typeof kicker === 'string') { return kicker; }
    return cloneElement(kicker!, {
      className: s.categoryHero__kickerImage,
    });
  };

  const t = gsap.timeline();
  t.addLabel('start', 0);

  useEffect(() => {
    if (isMobile) { reset(); return; }

    if (!complete) {
      animate();

      return () => {
        t.kill();
      };
    }
  }, [isMobile]);

  useEffect(() => { setHeaderConditional(); }, [isMobile]);

  const animate = () => {
    t.fromTo(
      wrapperNode.current!,
      { autoAlpha: 0 },
      { autoAlpha: 1,
        ease: 'none',
        duration: 1,
      },
      'start+=1');
    t.fromTo(
      overlayNode.current!,
      { scaleX: 0 },
      { scaleX: (5.5 / 12),
        ease: 'power3.inOut',
        transformOrigin: align === 'left' ? 'top left' : 'top right',
        duration: 1,
      },
      'start+=1.8');
    t.fromTo(
      backgroundNode.current!,
      { x: '0%' },
      { x: `${backgroundWidth}%`,
      ease: 'power1.inOut',
      transformOrigin: align === 'left' ? 'top right' : 'top left',
      duration: 1,
    },
      'start+=1.8');
    t.fromTo(
      backgroundInnerNode.current!,
      { x: '0%' },
      { x: backgroundPosition,
      ease: 'power1.inOut',
      transformOrigin: align === 'left' ? 'top right' : 'top left',
      duration: 1,
    },
      'start+=1.8');

    if (!complete) {
      t.call(() => setComplete(true));
    }
  };

  // scaling down to mobile, remove transition effects
  const reset = () => {
    gsap.set([
      wrapperNode.current!,
      backgroundNode.current!,
      backgroundInnerNode.current!,
      overlayNode.current!,
    ], {clearProps: 'all'});
    setComplete(false);
  };

  return (
    <>
    <div className={s('categoryHero', align, { complete })} ref={wrapperNode}>
      <div className={s.categoryHero__inner}>
        <div className={s.categoryHero__backgroundOverlay} ref={overlayNode} />
        <div className={s.categoryHero__background} ref={backgroundNode}>
          <div className={s.categoryHero__backgroundInner} ref={backgroundInnerNode}>
            {children && cloneElement(children, {
              alignCaption: isMobile ? 'right' : align,
              showCaption: complete,
            })}
          </div>
        </div>
        <ViewportEnter
          once={false}
          threshold={0}
          onExit={() => { setHeaderTheme('dark'); }}
          onEnter={() => { setHeaderConditional(); }}
        >
          <div className={s.categoryHero__viewportEnter} />
        </ViewportEnter>
        <div className={s.categoryHero__content}>
          <Container>
            <div className={s.categoryHero__contentInner}>
              {kicker && (<div className={s.categoryHero__kicker}>{renderKicker()}</div>)}
              <h1 className={s.categoryHero__heading}>{heading}</h1>
            </div>
          </Container>
        </div>
      </div>
    </div>
    <div className={s('categoryHero__scrollIndicator', align)}>
      <ScrollIndicator />
    </div>
    </>
  );
};

CategoryHero.defaultProps = {
  align: 'left',
};
