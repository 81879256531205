import React from 'react';

interface IProps {
  children: React.ReactNode;
}

export const SliceContext = React.createContext({});

export const SliceProvider = ({ children }: IProps) => {

  const [activeGalleryImage, setActiveGalleryImage] = React.useState(undefined);
  const [info, setInfo] = React.useState([]);

  return (
    <SliceContext.Provider
      value={{
        activeGalleryImage,
        setActiveGalleryImage,
        info,
        setInfo,
      }}
    >
      {children}
    </SliceContext.Provider>
  );
};
