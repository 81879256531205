import React, { cloneElement, useRef, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

import { Caption } from 'components/caption/Caption';
import { HeroEmbed } from 'components/hero-embed/HeroEmbed';
import { PlayButton } from 'components/play-button/PlayButton';
import { Row } from 'components/layout/Row';

import s from './ArticleHeroItem.scss';

interface IProps {
  slide?: boolean;
  direction?: number;
  hoverDirection?: number;
  active?: boolean;
  clone?: boolean;
  previous?: boolean;
  kicker?: string | React.ReactElement<any>;
  heading: string;
  image: React.ReactElement<any>;
  imageCaption?: string;
  imageCredits?: string;
  video?: string;
  poster?: string;
  nextSlide?: React.ReactElement<any>;
  link: string;
  youtubeID?: string;
  button?: React.ReactElement<any> | null;
  animationStart?: () => void;
  animationEnd?: () => void;
}

export const ArticleHeroItem = ({
  slide = true, // true if carousel item
  direction,
  active,
  clone, // cloned element for children wrapper
  previous,
  hoverDirection,
  kicker,
  heading,
  image,
  link,
  imageCaption,
  imageCredits,
  video,
  poster,
  animationStart,
  animationEnd, // start & end events to prevent navigation while animating
  youtubeID, // modal video
  button, // for courses
  ...props
 }: IProps) => {

  const heroNode = useRef<HTMLDivElement>(null);
  const contentNode = useRef<HTMLDivElement>(null);
  const captionNode = useRef<HTMLDivElement>(null);
  const videoNode = useRef<HTMLVideoElement>(null);
  const [ready, updateReady] = useState(false);
  const hasUpdated = useRef(false);

  // transition
  const duration = 400;

  // if video modal, need to set some states
  const [videoPlaying, setVideoPlaying] = useState(false);

  // if kicker is an image it will need some styles
  const renderKicker = () => {
    if (typeof kicker === 'string') { return kicker; }
    return cloneElement(kicker!, {
      className: s.articleHeroItem__kickerImage,
    });
  };

  const onLoadedData = () => {
    if (!videoNode.current) { return; }
    videoNode.current.play();
  };

  useEffect(() => {
    if (!videoNode.current) { return; }

    videoNode.current.addEventListener('loadedmetadata', onLoadedData);
    videoNode.current.addEventListener('playing', () => { updateReady(true); });
    return () => {
      if (videoNode.current) {
        videoNode.current.removeEventListener('loadedmetadata', onLoadedData);
        videoNode.current.removeEventListener('playing', () => { updateReady(true); });
      }
    };
  }, []);

  // hover styles
  useEffect(() => {
    if (!slide || clone) {
      return;
    }

    //  dont run on mount
    if (!hasUpdated.current) {
      hasUpdated.current = true;
      return;
    }

    if (hoverDirection && hoverDirection !== 0) {
      gsap.to(heroNode.current!, {
        x: hoverDirection === 2 ? -110 : 110,
        duration: (duration / 1000),
        delay: 0.1,
      });
    } else {
      gsap.to(heroNode.current!, { x: '0%', delay: 0.1, duration: (duration / 1000) });
    }
  }, [hoverDirection]);

  // transition group events
  const onEnter = () => {
    if (!animationStart) {
      return;
    }

    animationStart(); // prevent carousel navigation
    // set up item to enter
    gsap.set(heroNode.current, { zIndex: 2 });
    gsap.set(contentNode.current!, { autoAlpha: 0 });
    if (captionNode.current) {
      gsap.set(captionNode.current!, { autoAlpha: 0 });
    }
  };

  const onEntered = () => {
    // load page once transition is complete
    navigate(link);
  };

  const onExit = () => {
    // stack active to top pre-animate out
    if (!slide) {
      return;
    }
    gsap.set(heroNode.current, { zIndex: 3 });
  };

  const onExiting = () => {
    // animate out
    if (!slide) {
      return;
    }

    gsap.to(heroNode.current!, {
      duration: (duration / 1000),
      x: direction === 2 ? '-100%' : '100%',
      onComplete: () => gsap.set(heroNode.current!, { x: '0%', zIndex: 0 }),
    });
  };

  const onExited = () => {
    if (!animationEnd) {
      return;
    }
    animationEnd(); // allow carousel navigation
  };

  const content = (
    <>
      <div className={s('articleHeroItem', { active, clone, previous, ready })} ref={heroNode}>
        <div className={s.articleHeroItem__inner}>
          {image && (
            <div className={s.articleHeroItem__imageWrap}>
              {cloneElement(image, {
                className: s.articleHeroItem__image,
                alt: imageCaption || '',
              })}
            </div>
          )}
          {video && (
            <div className={s.articleHeroItem__videoWrap}>
              <video
                className={s.articleHeroItem__video}
                muted
                playsInline
                src={video}
                loop
                ref={videoNode}
                poster={poster}
              />
            </div>
          )}

          {active && (
            <>
            <div className={s.articleHeroItem__contentContainer}>
              <Row>
                <div className={s.articleHeroItem__content} ref={contentNode}>
                  <div className={s.articleHeroItem__contentInner}>
                    {kicker && (<div className={s.articleHeroItem__kicker}>{renderKicker()}</div>)}
                    <h1 className={s.articleHeroItem__heading}>
                      {heading}
                    </h1>
                    {youtubeID && (
                      <div className={s.articleHeroItem__playButton}>
                        <PlayButton onClick={() => { setVideoPlaying(true); }} />
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>
            {(imageCaption || imageCredits) && (
              <div className={s.articleHeroItem__caption} ref={captionNode}>
                <Caption
                  position="inside"
                  align="right"
                  caption={imageCaption}
                  credits={imageCredits}
                />
              </div>
            )}
            </>
          )}
        </div>
        {clone && (
          <div className={s.articleHeroItem__hint}>
            <span className={s.articleHeroItem__hintText}>{heading}</span>
          </div>
        )}
    </div>
      {youtubeID && (
        <HeroEmbed play={setVideoPlaying} playing={videoPlaying} youtubeID={youtubeID} />
      )}
      {button && (
        <div className={s.articleHeroItem__buttonWrapper}>
          <div className={s.articleHeroItem__button}>
            {button}
          </div>
        </div>
      )}
    </>
  );

  if (slide) {
    return (
      <Transition
        {...props}
        timeout={duration + 100}
        onEnter={onEnter}
        onEntered={onEntered}
        onExit={onExit}
        onExiting={onExiting}
        onExited={onExited}
        unmountOnExit={false}
      >
        {content}
      </Transition>
    );
  }

  return content;
};

ArticleHeroItem.defaultProps = {
  hoverDirection: 0,
};
