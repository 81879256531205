import React from 'react';

import s from './PaginationButton.scss';

interface IProps {
  children: React.ReactNode;
  active?: boolean;
  page: number;
  onClick: (action: any) => void;
}

export const PaginationButton = ({ children, page, onClick, active }: IProps) => {

  function onClickHandler() {
    onClick(page);
  }

  return (
      <button onClick={onClickHandler} className={s('paginationButton', { active })}>
        {children}
      </button>
  );
};
