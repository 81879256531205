import React, { cloneElement, useState } from 'react';

import { Caption } from 'components/caption/Caption';
import { GalleryButton } from 'components/gallery/GalleryButton';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './Figure.scss';

interface IProps {
  image?: React.ReactElement<any>;
  description?: string;
  credits?: string;
  region?: string;
  quote?: string;
  captionAlignment?: 'left' | 'right';
  captionPosition?: 'inside' | 'outside';
  aspectRatio?: 'landscape' | 'square' | 'portrait';
  className?: string;
  imageNumber?: number;
}

export const Figure = ({
  image,
  description,
  region,
  quote,
  credits,
  captionPosition,
  captionAlignment,
  aspectRatio,
  className,
  imageNumber,
}: IProps) => {
  if (!image) { return null; }

  const [active, setActive] = useState(false);

  const imageUrl = image && image.props.image && image.props.image.url || undefined;

  const imageInfo = {
    url: imageUrl,
    region,
    credits,
    caption: description,
    quote,
  };

  return (
    <ViewportEnter onEnter={() => setActive(true)} threshold={0}>
      <figure className={s('figure', aspectRatio, className, { active })}>
        <div className={s('figure__imageWrap')}>
          <div className={s.figure__imageTransition}>
            {cloneElement(image, {
              className: s.figure__image,
              preserveRatio: false,
            })}
          </div>

          {imageUrl && (
          <div className={s.figure__button}>
            <GalleryButton imageInfo={imageInfo} />
          </div>
          )}
        </div>
        {(credits || description) && (
          <figcaption>
            <Caption
              caption={description}
              credits={credits}
              align={captionAlignment as 'left' | 'right'}
              position={captionPosition as 'inside' | 'outside'}
            />
          </figcaption>
        )}
        {imageNumber && (<span className={s.figure__number}>{imageNumber}</span>)}
      </figure>
    </ViewportEnter>
  );
};

Figure.defaultProps = {
  captionAlignment: 'left',
  captionPosition: 'inside',
  aspectRatio: 'landscape',
};
