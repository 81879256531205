import React, { cloneElement } from 'react';

import s from './ImageGroup.scss';

interface IProps {
  large?: React.ReactElement<any>;
  largePosition?: 'top' | 'bottom';
  medium?: React.ReactElement<any>;
  small?: React.ReactElement<any>;
  pairAlignment?: 'left' | 'right';
}

export const ImageGroup = ({ large, medium, small, largePosition, pairAlignment }: IProps) => (
  <div className={s('imageGroup')}>
    <div className={s.imageGroup__inner}>
      {large && (
        <div
          className={
            s('imageGroup__large',
            largePosition, pairAlignment === 'left' ? 'right' : 'left',
            { single: !medium && !small },
          )}
        >
        {cloneElement(large, {
          className: s.imageGroup__largeImage,
          preserveRatio: false,
          captionPosition: 'inside',
          captionAlignment: pairAlignment,
          aspectRatio: 'landscape'})}
        </div>
      )}

      {(medium || small) &&
        (<div className={s('imageGroup__pair', pairAlignment)}>
          <div className={s('imageGroup__row')}>
            <div className={s.imageGroup__medium}>
              {medium && cloneElement(medium, {
                captionPosition: 'outside',
                captionAlignment: pairAlignment === 'left' ? 'right' : 'left',
                aspectRatio: 'landscape'})}
            </div>
            <div className={s.imageGroup__small}>
              {small && cloneElement(small, {
                className: s.imageGroup__smallImage,
                captionPosition: 'outside',
                captionAlignment: pairAlignment === 'left' ? 'right' : 'left',
                aspectRatio: 'square'})}
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

ImageGroup.defaultProps = {
  largePosition: 'bottom', // positioning of large image
  pairAlignment: 'left',
};
