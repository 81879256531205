import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { SliceContext } from 'context/slices';

import s from './GalleryButton.scss';

interface IProps {
  imageInfo: { url: string };
}

export const GalleryButton = ({ imageInfo }: IProps) => {
  const { setActiveGalleryImage, setInfo } = useContext<any>(SliceContext);

  useEffect(() => {
    if (!setInfo) { return; }
    setInfo((v: any) => v.concat(imageInfo));
  }, []);

  if (!setActiveGalleryImage) {
    return null;
  }

  return (
    <button
      className={s.galleryButton}
      onClick={() => { setActiveGalleryImage(imageInfo.url); }}
    >
      <FormattedMessage id="open-gallery" />
    </button>
  );
};
