import React from 'react';
import Helmet from 'react-helmet';

import { ArticleCta } from 'components/article-cta/ArticleCta';

import ArticleGridMasonry from 'components/article-grid/ArticleGridMasonry';
import { ArticleCard } from 'components/article-card/ArticleCard';
import { Carousel } from 'components/carousel/Carousel';
import { CtaCarousel } from 'components/cta-carousel/CtaCarousel';
import { CtaButton } from 'components/cta-button/CtaButton';
import { CtaItem } from 'components/cta-carousel/CtaItem';
import { Figure } from 'components/figure/Figure';
import { ArticleHero } from 'components/article-hero/ArticleHero';
import { ArticleHeroItem } from 'components/article-hero/ArticleHeroItem';
import { CategoryHero } from 'components/category-hero/CategoryHero';
import { HeroBackground } from 'components/hero-background/HeroBackground';
import { Image } from 'components/image/Image';
import { ImageGroup } from 'components/image-group/ImageGroup';
import { Intro } from 'components/intro/Intro';
import { Link } from 'components/link/Link';
import { Map } from 'components/map/Map';
import { PaginationButton } from 'components/pagination/PaginationButton';
import { PageHeader } from 'components/page-header/PageHeader';
import { Pagination } from 'components/pagination/Pagination';
import { Segment } from 'components/layout/Segment';
import { UtilityCard } from 'components/utility-card/UtilityCard';
import { Partners } from 'components/partners/Partners';
import { PartnersItem } from 'components/partners/PartnersItem';
import { VideoFullscreen } from 'components/slices/VideoFullscreen';

const cards = [
  {
    heading: 'Iceland by ferry',
    category: 'events',
    image: require('assets/images/temp/ferry.png'),
    description: 'Do you use actual Lipsum or the description of lipsum? Doi you use actual Lipsum or the description of lipsum?',
    align: 'left' as 'left',
  },
  {
    heading: 'Whale wotching',
    category: 'Activity',
    image: require('assets/images/temp/whale.png'),
    description: '',
    align: 'left' as 'left',
  },
  {
    heading: 'Experience the peace and quiet of the Westfjords',
    category: 'events',
    image: require('assets/images/temp/northern-lights.png'),
    description: 'Do you use actual Lipsum or the description of lipsum?',
    align: 'right' as 'right',
  },
  {
    heading: 'Iceland by bicycle',
    category: 'Culture',
    image: require('assets/images/temp/cliff.png'),
    align: 'right' as 'right',
    description: '',
  },
  {
    heading: 'Public Transport',
    category: 'Culture',
    image: require('assets/images/temp/ferry.png'),
    align: 'left' as 'left',
    description: 'Do you use actual Lipsum or the description of lipsum? Doi you use actual Lipsum or the description of lipsum?',
  },
  {
    heading: 'Public Transport',
    category: 'Culture',
    image: require('assets/images/temp/reykjanes.png'),
    align: 'left' as 'left',
    description: 'Do you use actual Lipsum or the description of lipsum? Doi you use actual Lipsum or the description of lipsum?',
  },
];

const partnersList = [
  {
    link: 'https://www.re.is/',
    name: 'Reykjavik Excursions',
    image: <Image src={require('assets/images/partners/reykjavik-excursions.png')} width={150} height={54} />,
  },
  {
    link: 'https://www.heyiceland.is/',
    name: 'Hey Iceland',
    image: <Image src={require('assets/images/partners/hey-iceland.png')} width={90} height={90} />,
  },
  {
    link: 'https://www.airicelandconnect.com/',
    name: 'Air Iceland Connect',
    image: <Image src={require('assets/images/partners/air-iceland-connect.png')} width={155} height={36} />,
  },
  {
    link: 'http://www.katla-dmi.is/en/',
    name: 'Katla DMI',
    image: <Image src={require('assets/images/partners/katla-dmi.png')} width={84} height={102} />,
  },
  {
    link: 'https://adventures.is/',
    name: 'Arctic Adventures',
    image: <Image src={require('assets/images/partners/arctic-adventures.png')} width={92} height={91} />,
  },
  {
    link: 'https://grayline.is/',
    name: 'Snæland Travel',
    image: <Image src={require('assets/images/partners/snaeland-travel.png')} width={150} height={60} />,
  },
];

const carouselItems = [
  {heading: 'Public Transport', category: 'Culture', image: require('assets/images/temp/ferry.png')},
  {heading: 'Sagaland', category: 'something', image: require('assets/images/temp/sagaland.png')},
  {heading: 'A road trip around Iceland', category: 'events', image: require('assets/images/temp/sitting.png')},
  {heading: 'clambering about', category: 'events', image: require('assets/images/temp/cliff.png')},
  {heading: 'Iceland by bicycle', category: 'shoemaking', image: require('assets/images/temp/biking.png')},
  {heading: 'Iceland by bicycle', category: 'events', image: require('assets/images/temp/northern-lights.png')},
  {heading: 'Iceland by bicycle', category: 'events', image: require('assets/images/temp/ferry.png')},
  {heading: 'Iceland by bicycle', category: 'eventz', image: require('assets/images/temp/food.png')},
  {heading: 'Iceland by bicycle', category: 'Znts', image: require('assets/images/temp/biking.png')},
  {heading: 'Iceland by bicycle', category: 'events', image: require('assets/images/temp/northern-lights.png')},
  {heading: 'The last one', category: '404', image: require('assets/images/temp/seagulls.png')},
];

const activePagination = 2;
const items: any = [];
for (let paginationNumber = 1; paginationNumber <= 5; paginationNumber++) {
  items.push(
    <PaginationButton to="/" key={paginationNumber} active={paginationNumber === activePagination}>
      {paginationNumber}
    </PaginationButton>,
  );
}

export default () => {

  return (
    <>
      <Helmet title="Elements" />

      <Segment>
        <Pagination>{items}</Pagination>
      </Segment>

      <Segment>
        <Carousel rowHeading="Other academy classes" description="Discover the a-ö of the seven regions of Iceland.">
          {carouselItems.map((item, i) => (
            <UtilityCard key={i} heading={item.heading} category={item.category}>
              <Image src={item.image} width={400} height={300} />
            </UtilityCard>
          ))}
        </Carousel>
      </Segment>

      <Segment>
        <Carousel heading="Other academy classes" description="Discover the a-ö of the seven regions of Iceland.">
          {carouselItems.map((item, i) => (
            <UtilityCard key={i} heading={item.heading} category={item.category}>
              <Image src={item.image} width={400} height={300} />
            </UtilityCard>
          ))}
        </Carousel>
      </Segment>

      <PageHeader
        heading="here is a page header"
        backLink={<Link to="/">go back</Link>}
        text="scascasc up a thingk I mean"
      />

      <ArticleHero>
      <ArticleHeroItem
        link="/"
        heading="item video"
        kicker="introducing..."
        video={require('assets/videos/temp/hero-compressed.mp4')}
        image={
          <Image
            src={require('assets/images/temp/hero-compressed-poster.jpg')}
          />
        }
        imageCaption="two m**** climbing up a thingk I mean wot are they doung"
        imageCredits="tondy dazzle"
      />
        <ArticleHeroItem
          link="/"
          heading="item with youtube video"
          kicker="introducing..."
          imageCaption="two m**** climbing up a thingk I mean wot are they doung"
          imageCredits="tondy dazzle"
          youtubeID="hpLTXY_LL9Q"
          image={
            <Image
              src={require('assets/images/temp/climbers.png')}
            />
          }
        />
        <ArticleHeroItem
          link="/"
          heading="item one"
          kicker="introducing..."
          imageCaption="two m**** climbing up a thingk I mean wot are they doung"
          imageCredits="tondy dazzle"
          image={
            <Image
              src={require('assets/images/temp/climbers.png')}
            />
          }
        />
        <ArticleHeroItem
          link="/"
          heading="item two tow too"
          kicker="introducing.sss.."
          image={
            <Image
              src={require('assets/images/temp/sagaland.png')}
            />
          }
        />
        <ArticleHeroItem
          link="/"
          heading="item three 3 3 3 "
          kicker="introducing..."
          image={
            <Image
              src={require('assets/images/temp/reykjanes.png')}
            />
          }
        />
      </ArticleHero>

      <CategoryHero kicker="Things to do" heading="A Year-round Destination">
        <HeroBackground
          video={require('assets/videos/temp/hero-compressed.mp4')}
          image={require('assets/images/temp/hero-compressed-poster.jpg')}
          description="this is some geysir i think"
          credits="Tony Lamezma"
        />
      </CategoryHero>

      <Segment>
        <ArticleCta
          heading="Tour Companies in Iceland"
          image={
            <Image
              src={require('assets/images/temp/climbers.png')}
            />
          }
          link="/"
        />
      </Segment>

      <Segment container>
        <p>
          <CtaButton
            link="/"
          >
            I can Link anywhere
          </CtaButton>
        </p>
      </Segment>

      <Intro>
        Every day, there is an adventure waiting to happen in iceland.
        with its abundance of mountains, volcanoes, glaciers, rivers,
        lakes, caves and otherwise rough terrain waiting to be tackled,
        iceland is truly an outdoor enthusiast’s paradise. but, it is
        also a warm and welcoming place for the rest of us.
      </Intro>

      <Intro separator>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy
        ext ever since the 1500s, when an unknown printer took a galley
        of type and scrambled it to make a type specimen book.
      </Intro>

      <ArticleGridMasonry>
        {cards.map((item, i) => (
          <ArticleCard
            key={i}
            heading={item.heading}
            category={item.category}
            image={<Image src={item.image} />}
            description={item.description}
            align={item.align}
          />
        ))}
      </ArticleGridMasonry>

      <Segment container>
        <Map size="large" region="all" />
      </Segment>

      <VideoFullscreen
        heading="Video"
        kicker="introducing..."
        youtubeID="hpLTXY_LL9Q"
        video={require('assets/videos/temp/hero-compressed.mp4')}
        image={
          <Image
            src={require('assets/images/temp/hero-compressed-poster.jpg')}
          />
        }
        imageCaption="Lorem ipsum dolor sit, video bingo lorem doo"
        imageCredits="tondy dazzle"
      />

      <Segment container>
        <Figure
          image={<Image src={require('assets/images/temp/sagaland.png')} />}
          description="Gullfoss, South Gullfoss,
          South Gullfoss, South  Gullfoss,
          South Gullfoss, South South Gullfoss
          South GullfossSouth Gullfoss"
          credits="Whitney Justesen"
          captionPosition="inside"
          captionAlignment="right"
        />

      </Segment>

      <Segment>
        <ImageGroup
          pairAlignment="left"
          largePosition="bottom"
          large={
            <Figure
              image={<Image src={require('assets/images/temp/reykjanes.png')} />}
              description="Gullfoss, South"
              credits="Whitney Justesen"
            />
          }
          medium={
            <Figure
              image={<Image src={require('assets/images/temp/foss.png')} />}
              description="Gullfoss, South"
              credits="Whitney Justesen"
            />
          }
          small={
            <Figure
              image={<Image src={require('assets/images/temp/foss.png')} />}
              description="Gullfoss, South"
              credits="Whitney Justesen"
            />
          }
        />

        </Segment>

        <Segment>

        <ImageGroup
          pairAlignment="right"
          largePosition="top"
          large={
            <Figure
              image={<Image src={require('assets/images/temp/whale.png')} />}
              description="Gullfoss, South"
              credits="Whitney Justesen"
            />
          }
          medium={
            <Figure
              image={<Image src={require('assets/images/temp/sagaland.png')} />}
              description="Gullfoss, South"
              credits="Whitney Justesen"
            />
          }
          small={
            <Figure
              image={<Image src={require('assets/images/temp/foss.png')} />}
              description="Gullfoss, South"
              credits="Whitney Justesen"
            />
          }
        />
      </Segment>

      <Segment container>
        <Partners
          title="Trusted Partners"
          align="right"
        >
          {partnersList.map((item, i) => (
            <PartnersItem link={item.link} name={item.name} key={`partners-${i}`}>
              {item.image}
            </PartnersItem>
          ))}
        </Partners>
      </Segment>

      <Segment>
          <CtaCarousel>
            <CtaItem
              link="/"
              kicker="Responsible tourism"
              heading="Take the Icelandic Pledge"
              text="Do you use actual Lipsum or the description of lipsum? Doi you use actual Lipsum or the description of lipsum?"
              image={<Image src={require('assets/images/temp/pledge.jpg')} />}
            />

            <CtaItem
              link="/"
              kicker={
                <Image
                  src={require('assets/images/iceland-academy-white.png')}
                  width={170}
                  height={34}
                />
              }
              heading="go to hererere"
              text="Do you use actual Lipsum or the description of lipsum? Doi you use actual Lipsum or the description of lipsum?"
              image={<Image src={require('assets/images/temp/ferry.png')} />}
            />

            <CtaItem
              link="/"
              kicker="ascascasc"
              heading="go to hererere ok we got a long"
              text="Do you use actual Lipsum or the description of lipsum? Doi you use actual Lipsum or the description of lipsum?"
              image={<Image src={require('assets/images/temp/whale.png')} />}
            />
          </CtaCarousel>
      </Segment>
    </>
  );
};
