import React, { cloneElement, useState, useRef, useEffect, Children } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { flatten } from 'lodash';

import { useSwipe } from 'hooks/use-swipe';
import { UIContext } from 'context/ui';

import { CarouselButton } from 'components/carousel-button/CarouselButton';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './ArticleHero.scss';

interface IProps {
  children?: any;
}

const slideDirection = {
  FORWARD: 2,
  BACKWARD: 1,
};

export const ArticleHero = ({ children = [] }: IProps) => {

  const childNodes = flatten(children);
  const [direction, setDirection] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const [hoverDirection, setHoverDirection] = useState(0);

  const carouselNode = useRef<HTMLDivElement>(null);

  const swipeDirection = useSwipe(carouselNode);

  const { setHeaderTheme, setCanTransition, canTransition, isDesktop } = React.useContext<any>(UIContext);

  const slides = Children.toArray(childNodes);
  const slideCount = slides.length;

  const next = activeSlide + 1 <= (slideCount - 1) ? activeSlide + 1 : 0;
  const prev = activeSlide - 1 >= 0 ? activeSlide - 1 : slideCount - 1;

  const slide = slides[activeSlide] as React.ReactElement<any>;
  const nextSlide = slides[next] as React.ReactElement<any>;
  const prevSlide = slides[prev] as React.ReactElement<any>;

  const onEnter = (dir: number) => {
    if (!isAnimating) {
      setHoverDirection(dir);
    }
  };

  const onLeave = () => {
    setHoverDirection(0);
  };

  // set state async, so need to be sure we got direction set correctly
  // if direction is not next, update it and let useEffect handle update : else just set next slide
  const onNext = () => {
    direction !== slideDirection.FORWARD ? setDirection(slideDirection.FORWARD) : setActiveSlide(next);
    setHoverDirection(0);
  };

  const onPrev = () => {
    direction !== slideDirection.BACKWARD ? setDirection(slideDirection.BACKWARD) : setActiveSlide(prev);
    setHoverDirection(0);
  };

  // update slide if direction changes
  useEffect(() => {
    if (direction) {
      setActiveSlide(direction === slideDirection.BACKWARD ? prev : next);
    }
  }, [direction]);

  // on swipe update
  useEffect(() => {
    if (!swipeDirection || isAnimating) { return; }

    if (swipeDirection === 'left') {
      onNext();
    } else if (swipeDirection === 'right') {
      onPrev();
    }}, [swipeDirection]);

  useEffect(() => {
    setCanTransition(false);
    setTimeout(() => setIsAnimating(false), 1000);
  }, []);

  // flag to only create carousel if enough slides
  const isCarousel = slideCount >= 3;

  return (
    <>
      <div className={s('articleHero', { isAnimating, transition: !canTransition })} ref={carouselNode}>
        <div className={s.articleHero__inner}>
        {/* render kids for cache if ncessesary */}
          {slideCount > 3 && (<div className={s.articleHero__children}>{childNodes}</div>)}

          {isCarousel && prevSlide && cloneElement(prevSlide as React.ReactElement<any>, {
            direction,
            clone: true,
            previous: true,
          })}

          {isCarousel ? (
            <TransitionGroup>
            {cloneElement(slide, {
              direction,
              active: true,
              animationStart: () => setIsAnimating(true),
              animationEnd: () => setIsAnimating(false),
              hoverDirection,
            })}
          </TransitionGroup>
          ) : cloneElement(slide, { active: true, slide: false })}

          {isCarousel && nextSlide && cloneElement(nextSlide, {
            direction,
            clone: true,
          })}
        </div>

        {isCarousel && (
          <div className={s.articleHero__buttonWrap}>
              <CarouselButton
                direction="previous"
                onClick={onPrev}
                disabled={isAnimating}
                onMouseEnter={() => {onEnter(slideDirection.BACKWARD); }}
                onMouseLeave={onLeave}
                className={s('articleHero__button', { active: !isAnimating })}
              />

              <CarouselButton
                direction="next"
                onClick={onNext}
                disabled={isAnimating}
                onMouseEnter={() => {onEnter(slideDirection.FORWARD); }}
                onMouseLeave={onLeave}
                className={s('articleHero__button', { active: !isAnimating })}
              />
          </div>
        )}
      </div>

      <ViewportEnter once={false} threshold={0} onExit={() => { setHeaderTheme('dark'); }} onEnter={() => { setHeaderTheme('light'); }}>
        <div className={s.articleHero__viewportEnter} />
      </ViewportEnter>

      <div className={s('articleHero__scrollIndicator', { hidden: hoverDirection === slideDirection.BACKWARD || !isDesktop })}>
        <ScrollIndicator />
      </div>
    </>
  );
};
