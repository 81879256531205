import React from 'react';
import { FormattedMessage } from 'react-intl';

import s from './Caption.scss';

interface IProps {
  caption?: string;
  credits?: string;
  align: 'left' | 'right';
  position?: 'inside' | 'outside';
  className?: string;
  type?: 'video' | 'photo';
}

export const Caption = ({ className, caption, type, credits, position, align }: IProps) => (
  <div className={s( className, 'caption', position, align )}>
    {caption && <span className={s.caption__caption}>{caption}</span>}
    {caption && credits && ' '}
    {credits && <span className={s.caption__credits}><FormattedMessage id={type === 'photo' ? 'photo-by' : 'video-by'} /> {credits}</span>}
  </div>
);

Caption.defaultProps = {
  type: 'photo',
};
