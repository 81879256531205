import React, { useRef, useEffect, useState } from 'react';

import { Caption } from 'components/caption/Caption';

import s from './HeroBackground.scss';

interface IProps {
  image: string;
  imageMobile?: string;
  video?: string;
  description?: string;
  credits?: string;
  showCaption?: boolean;
  alignCaption?: 'left' | 'right';
}

export const HeroBackground = ({ image, imageMobile, video, description, credits, showCaption, alignCaption }: IProps) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const [ready, updateReady] = useState(false);

  const onLoadedData = () => {
    if (!videoNode.current) { return; }
    videoNode.current.play();
  };

  useEffect(() => {
    if (!videoNode.current) { return; }

    videoNode.current.addEventListener('loadedmetadata', onLoadedData);
    videoNode.current.addEventListener('playing', () => { updateReady(true); });
    return () => {
      if (videoNode.current) {
        videoNode.current.removeEventListener('loadedmetadata', onLoadedData);
        videoNode.current.removeEventListener('playing', () => { updateReady(true); });
      }
    };
  }, []);

  return (
    <div className={s('heroBackground', { showCaption, ready })}>
      <picture>
        {imageMobile ? (
          <>
          <source media="(min-width: 720px)" srcSet={image} />
          <source media="(max-width: 719px)" srcSet={imageMobile} />
          </>
        ) :
          (<source srcSet={image} />)
        }
        <img className={s.heroBackground__image} width="1600" height="1067" alt="" />
      </picture>

      {video && (
        <div className={s.heroBackground__videoWrap}>
          <video
            className={s.heroBackground__video}
            muted
            playsInline
            src={video}
            loop
            ref={videoNode}
            // poster={image}
          />
        </div>
      )}

      {(description || credits) && (
        <div className={s('heroBackground__caption', alignCaption)}>
          <Caption
            type={video ? 'video' : 'photo'}
            position="inside"
            align={alignCaption as 'left' | 'right'}
            caption={description}
            credits={credits}
          />
        </div>
      )}
    </div>
  );
};
